import { render, staticRenderFns } from "./problemFeedback-list.vue?vue&type=template&id=904a5152&scoped=true"
import script from "./problemFeedback-list.vue?vue&type=script&lang=js"
export * from "./problemFeedback-list.vue?vue&type=script&lang=js"
import style0 from "./problemFeedback-list.vue?vue&type=style&index=0&id=904a5152&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "904a5152",
  null
  
)

export default component.exports